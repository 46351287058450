import React, { useState } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HeroSection, {HeroImage, HeroProviderLogoImage} from "../components/HeroSection";
import Section from "../components/Section";
import Feature from "../components/Feature";
import { graphql, useStaticQuery } from "gatsby";
import AppStoreLinks from "../components/AppStoreLinks";
import Img from "gatsby-image";
import { H1, H2, H3 } from "../components/TextHeadings";
import SignUpForm from "../components/SignUpForm";
import Notification from "../components/Notification";
import notificationService from "../utils/notificationService";
import Paragraph  from "../components/Paragraph";
import { windowSize } from "../utils/responsive";
import colors from "../utils/colors";

const IndexPage = () => {
  const [message, setMessage] = useState(null);
  const data = useStaticQuery(graphql`
    query {
      landingHero: file(relativePath: { eq: "sp-landing-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      qrCode: file(relativePath: { eq: "QR-get-app.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perkBoard: file(relativePath: { eq: "perk-board.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perkBoardMobile: file(relativePath: { eq: "perk-board-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 856) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perks: file(relativePath: { eq: "perks.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sentiment: file(relativePath: { eq: "sentiment.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      surveys: file(relativePath: { eq: "survey.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      communication: file(relativePath: { eq: "communication.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perkCentral: file(relativePath: { eq: "corp-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
        niriLogo: file(relativePath: { eq: "niri-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 818) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleSubmit = (email, clearText) => {
    const payload = { email };

    notificationService
      .notify(payload)
      .then(resp => {
        setMessage({
          text: "Thanks! We will get back to you soon.",
          type: "success"
        });
        clearText();
      })
      .catch(error => {
        setMessage({
          text: "Oops! Something went wrong.",
          type: "error"
        });
        clearText();
      });
  };

  return (
    <Layout>
      <SEO
        title="Stockperks"
        url={"/"}
        image={data.landingHero.childImageSharp.fluid.src}
      />
      <HeroSection isDarkBackground={true}>
        <div style={styles.HeroContainer}>
          <div style={styles.HeroText}>
            <H1>Get rewarded for investing in the companies you know and love</H1>
            <H3>The free app for retail shareholders that offers perks, discounts, experiences and more.</H3>
            <AppStoreLinks />
          </div>
          <div>
            <HeroImage
              fluid={data.landingHero.childImageSharp.fluid}
              alt="Stockperks app screenshots"
            />
          </div>
        </div>
      </HeroSection>
      <Section isTextCentered={true}>
        <div style={styles.GoldenAgeContainer}>
          <H1>
            Welcome to the new golden age of investing
          </H1>
          <Paragraph style={{marginBottom: 40}}>
            Stockperks seamlessly connects investors to companies, offering a modern engagement experience that unlocks increased shareholder value
          </Paragraph>
          <Img
            fluid={[
              data.perkBoardMobile.childImageSharp.fluid,
              {
                ...data.perkBoard.childImageSharp.fluid,
                media: `(min-width: 768px)`,
              }
            ]}
            alt="Perks offer"
          />
        </div>
      </Section>

      <Section>
        <Feature
          image={data.perks.childImageSharp.fluid}
          imageAlt="Perks tab screenshot"
          style={{paddingTop: 0}}
        >
          <H2>Discover perks</H2>
          <Paragraph>
            More and more companies are offering perks to their shareholders as a reward for investment loyalty. Easily browse for discounts, free items, and special experiences.
          </Paragraph>
        </Feature>
        <Feature
          image={data.communication.childImageSharp.fluid}
          imageAlt="Communication tab screenshot"
          isImageRight={true}
          stripe={true}
        >
          <H2>Communication</H2>
          <Paragraph>
            Receive tailored messaging from companies about new perks, important shareholder information, and new products and services. All without cluttering up your email.
          </Paragraph>
        </Feature>
      </Section>
      <Section style={styles.CorporateSection}>
          <div style={styles.CorporateContainer}>
            <H2>Issuers, are you engaging your retail investors?</H2>
            <Paragraph>
              Engage and reward your retail shareholders, year-round. Reward your retail shareholders for their loyalty with exciting perks, including exclusive product offerings, discounts, free items, special experiences and more.
            </Paragraph>
            <SignUpForm
                handleSubmit={handleSubmit}
                placeholder="Your email address"
                submitText="Connect"
            />
            <br />
            {message && message.text && (
                <Notification text={message.text} type={message.type || null} />
            )}
            <div style={styles.ProviderLogo}>
              <HeroProviderLogoImage fluid={data.niriLogo.childImageSharp.fluid} alt={"Niri logo"} />
            </div>
          </div>
          <div style={styles.PerkCentralImage}>
            <HeroImage fluid={data.perkCentral.childImageSharp.fluid} />
          </div>
      </Section>
    </Layout>
  );
};

const styles = {
  CorporateSection: {
    backgroundColor: colors.mint,
    height: 660,
    alignItems: "flex-start",
    display: "flex",
    flexDirection:"row",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 25
  },
  CorporateContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 718,
  },
  GoldenAgeContainer: {
    marginTop: 25,
    padding: 25,
    width: "calc(100% - 50px)",
    maxWidth:windowSize.large
  },
  HeroContainer: {
    display: "flex",
    flexDirection:"row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent:"center"
  },
  HeroText: {
    maxWidth: "548px",
    textAlign: "left",
    alignItems:"flex-start",
    display:"flex",
    flexDirection:"column",
  },
  ProviderLogo: {
    alignSelf:"flex-start",
    paddingTop: 10
  },
  PerkCentralImage: {
    paddingLeft:25,
    paddingRight: 25
  }
}

export default IndexPage;
